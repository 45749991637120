import React, { useState } from 'react';
import { Typography, Box, ImageList, ImageListItem, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import itemData from '../../utils/galleryData'
import '../../App.css';

const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 1, delay: 1 }}
    >
      <Box className="section section2">
        <Typography variant="h3" component="h2" gutterBottom sx={{ textAlign: 'center', color: 'var(--primary-color)' }} className="text-shadow">
          Braille Skills Puzzle
        </Typography>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <Tooltip key={item.img} title={item.description} placement="top" arrow>
              <ImageListItem 
                className="gallery-item" 
                onClick={() => handleClickOpen(item.img)} 
                role="button" 
                tabIndex="0"
                onKeyDown={(e) => { if (e.key === 'Enter') handleClickOpen(item.img); }}
              >
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.description}
                  loading="lazy"
                  style={{ cursor: 'pointer' }}
                  aria-label={item.title}
                />
              </ImageListItem>
            </Tooltip>
          ))}
        </ImageList>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />
          )}
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};

export default Gallery;
