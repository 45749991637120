import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCart from '../Store/Utils/useCart'; 
import { motion } from 'framer-motion';
import CompletedPuzzle from '../../assets/images/grey-completed-puzzle.jpg';

const ReservationPage = () => {
  const navigate = useNavigate();
  const { cart, addToCart } = useCart();
  const [inCart, setInCart] = useState(false);
  const [reservationsFull, setReservationsFull] = useState(false);

  const reservationProduct = {
    id: 'reservation-1',
    title: 'Braille Skills Puzzle (Reservation)',
    description: 'Reservation for the upcoming Braille Skills Puzzle release.',
    price: 1.00,
    quantity: 1,
    img: CompletedPuzzle,
    requiresShipping: false
  };

  useEffect(() => {
    const isInCart = cart.some((item) => item.id === reservationProduct.id);
    setInCart(isInCart);

    // Fetch the current reservation count
    const checkReservationLimit = async () => {
      try {
        const response = await fetch('https://4sr8xw2cgk.execute-api.us-west-2.amazonaws.com/prod/reservation-count');
        const data = await response.json();
        console.log("Reservation count: " + data.reservationCount)

        if (data.reservationCount >= 100) {
          setReservationsFull(true);
        }
      } catch (error) {
        console.error('Error checking reservation limit:', error);
      }
    };

    checkReservationLimit();
  }, [cart]);

  const handleReserve = () => {
    if (!reservationsFull) {
      addToCart(reservationProduct);
      if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
          event_category: 'Ecommerce',
          event_label: reservationProduct.title,
          value: reservationProduct.price,
        });
      }
      navigate('/cart');
    }
  };

  return (
    <Box sx={{ 
      padding: '20px', 
      maxWidth: '800px', 
      margin: 'auto', 
      marginTop: '30px',
      background: 'linear-gradient(135deg, #f3f4f6, #e5e7eb)',
      borderRadius: '15px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center', color: 'var(--primary-color)', fontWeight: 'bold' }}>
          Reserve Your Puzzle
        </Typography>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '20px', textAlign: 'center', color: '#4b5563' }}>
          Be among the first to get our exclusive upcoming Braille Skills Puzzle by reserving your spot today!
        </Typography>

        <Card sx={{ 
          marginBottom: '20px', 
          padding: '20px', 
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}>
          <CardContent>
            <Typography variant="body1" sx={{ color: '#1f2937' }}>
              For just $1, you can reserve the opportunity to purchase our latest puzzle when it is released. 
              By reserving, you ensure that you won’t miss out on our limited stock, and you'll be notified first when the puzzle becomes available.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px', color: '#6b7280' }}>
              The product is expected to be ready within 45 days. If it's not ready within this timeframe, your $1 reservation fee will be fully refunded.
            </Typography>
            {reservationsFull && (
              <Typography variant="body2" sx={{ marginTop: '10px', color: 'red' }}>
                We have reached the maximum number of reservations. Thank you for your interest!
              </Typography>
            )}
          </CardContent>
        </Card>

        <Box sx={{ textAlign: 'center' }}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button 
              variant="contained" 
              sx={{
                backgroundColor: inCart || reservationsFull ? '#1B9710' : 'var(--primary-color)',
                borderRadius: '30px',
                padding: '12px 25px',
                fontSize: '16px',
                boxShadow: '0 4px 14px rgba(0, 118, 255, 0.3)',
                '&:hover': {
                  backgroundColor: inCart || reservationsFull ? '#158306' : 'var(--primary-color)',
                },
                color: '#fff',
              }}
              onClick={handleReserve}
              disabled={inCart || reservationsFull} 
            >
              {reservationsFull ? 'Reservations Full' : inCart ? 'Reservation in Cart' : 'Reserve Now for $1'}
            </Button>
          </motion.div>
        </Box>
      </motion.div>
    </Box>
  );
};

export default ReservationPage;
