// src/components/Home/ReservationPromo.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const ReservationPromo = () => {
  const navigate = useNavigate();

  const handleReserveClick = () => {
    navigate('/reservation');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Typography variant="h4" component="h2" sx={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>
          Reserve Your Braille Skills Puzzle Today!
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '15px', marginBottom: '20px', color: '#4b5563' }}>
          Secure your chance to purchase our upcoming Braille Skills Puzzle by reserving it today for just $1. 
          <br />
          Don't miss out on this exclusive opportunity to be among the first to get this innovative educational tool.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'var(--primary-color)',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#00509d',
            },
            padding: '10px 20px',
          }}
          onClick={handleReserveClick}
        >
          Reserve Now
        </Button>
      </motion.div>
    </Box>
  );
};

export default ReservationPromo;
