import CompletedPuzzle from '../assets/images/puzzle-2.jpg';
import TrayAssortedPieces from '../assets/images/puzzle-3.jpg';
import CompletedPuzzleVisibleDots from '../assets/images/puzzle-4.jpg';
import GreenCompletedPuzzle from '../assets/images/green-completed-puzzle.jpg';
import AssortedPieces from '../assets/images/assorted-puzzle-pieces.jpg';
import BlackTray from '../assets/images/black-puzzle-tray.jpg';
import GreenPieces from '../assets/images/green-pieces-together.jpg';
import BlackAndBlueTrays from '../assets/images/black-and-blue-trays.jpg';
import BlackHighContrastTray from '../assets/images/black-high-contrast-puzzle-tray.jpg';
import WhitePurpleHighContrastTray from '../assets/images/white-purple-high-contrast-puzzle-tray.jpg';
import HighContrastPuzzleTrays from '../assets/images/high-conrtast-puzzle-trays.jpg';

const itemData = [
    {
      img: CompletedPuzzle,
      title: 'Completed Braille skills puzzle',
      description: 'White puzzle tray with red puzzle pieces. Puzzle pieces have raised geometric shapes and braille dots for easy tactile discrimination.'
    },
    {
      img: TrayAssortedPieces,
      title: 'Braille skills puzzle trays and assorted pieces',
      description: 'Tray and puzzle pieces both have braille dots.'
    },
    {
      img: BlackAndBlueTrays,
      title: 'Braille skills puzzle black and blue trays',
      description: 'Braille skills puzzle black and blue trays'
    },
    {
      img: CompletedPuzzleVisibleDots,
      title: 'Completed Braille skills puzzle with highly visible braille dots',
      description: 'Completed Braille skills puzzle with highly visible braille dots'
    },
    {
      img: BlackTray,
      title: 'Braille skills puzzle black tray',
      description: 'Braille skills puzzle black tray'
    },
    {
      img: AssortedPieces,
      title: 'Assorted Braille puzzle pieces',
      description: 'Multicolored collection of Braille puzzle pieces'
    },
    {
      img: GreenPieces,
      title: 'Braille skills puzzle completed green set',
      description: 'Green puzzle pieces with raised geometric shapes'
    },
    {
      img: GreenCompletedPuzzle,
      title: "Set of green Braille puzzle pieces",
      description: "Green transparent puzzle pieces with raised geometric shapes and Braille dots laid out in two rows of five."
    },
    {
      img: BlackHighContrastTray,
      title: "Black High Contrast Puzzle Tray",
      description: "These Braille Skills Puzzle trays feature interlocking puzzle pieces framed in various colors. Each tray includes distinct puzzle pieces designed to fit into corresponding voids. Braille numbers are embossed within the voids and on the puzzle pieces, enabling tactile learning for visually impaired users. The trays are durable and portable, designed for educational purposes, and help users engage with Braille through touch and matching activities. The layout encourages both independent learning and group play, reinforcing numeracy and tactile discrimination"
    },
    {
      img: WhitePurpleHighContrastTray,
      title: "White and Purple High Contrast Puzzle Tray",
      description: "These Braille Skills Puzzle trays feature interlocking puzzle pieces framed in various colors. Each tray includes distinct puzzle pieces designed to fit into corresponding voids. Braille numbers are embossed within the voids and on the puzzle pieces, enabling tactile learning for visually impaired users. The trays are durable and portable, designed for educational purposes, and help users engage with Braille through touch and matching activities. The layout encourages both independent learning and group play, reinforcing numeracy and tactile discrimination"
    },
    {
      img: HighContrastPuzzleTrays,
      title: "Various High Contrast Puzzle Trays",
      description: "These Braille Skills Puzzle trays feature interlocking puzzle pieces framed in various colors. Each tray includes distinct puzzle pieces designed to fit into corresponding voids. Braille numbers are embossed within the voids and on the puzzle pieces, enabling tactile learning for visually impaired users. The trays are durable and portable, designed for educational purposes, and help users engage with Braille through touch and matching activities. The layout encourages both independent learning and group play, reinforcing numeracy and tactile discrimination"
    }
  ];
export default itemData;
