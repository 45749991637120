import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ReservationModal = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    handleClose();
    navigate('/reservation'); // Replace with your actual reservation page route
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="reservation-modal-title"
      aria-describedby="reservation-modal-description"
    >
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 400, 
        bgcolor: 'background.paper', 
        border: '2px solid #000', 
        boxShadow: 24, 
        p: 4 
      }}>
        <Typography id="reservation-modal-title" variant="h6" component="h2">
          Reserve Your Puzzle!
        </Typography>
        <Typography id="reservation-modal-description" sx={{ mt: 2 }}>
          For just $1, you can reserve the option to purchase our upcoming puzzles when they are released.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 3, backgroundColor: 'var(--primary-color)' }}
          onClick={handleLearnMore}
        >
          Learn More
        </Button>
      </Box>
    </Modal>
  );
};

export default ReservationModal;
